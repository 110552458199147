%sectionDefaultStyles {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.intro {
  @extend %sectionDefaultStyles;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.intro::before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.589);
}

.sectionTitle {
  font-size: 3rem;
  color: white;
  margin-top: 1rem;
}

.introTextWrapper {
  margin-top: 10%;

  p:nth-child(1) {
    width: 42%;
    animation: type 2s steps(13, end), blink-caret 0.75s step-end infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    @media (max-width: 575px) {
      /*CSS transitions*/
      -o-transition-property: none !important;
      -moz-transition-property: none !important;
      -ms-transition-property: none !important;
      -webkit-transition-property: none !important;
      transition-property: none !important;
      /*CSS transforms*/
      -o-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -webkit-transform: none !important;
      transform: none !important;
      /*CSS animations*/
      -webkit-animation: none !important;
      -moz-animation: none !important;
      -o-animation: none !important;
      -ms-animation: none !important;
      animation: none !important;
    }
  }

  p:nth-child(2) {
    animation: type2 1.5s steps(22, end), blink-caret 0.75s step-end infinite;
    // width: 7.3em;
    opacity: 0;
    width: 65%;
    -webkit-animation-delay: 2.1s;
    animation-delay: 2.1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    @media (max-width: 575px) {
      /*CSS transitions*/
      opacity: 1;
      -o-transition-property: none !important;
      -moz-transition-property: none !important;
      -ms-transition-property: none !important;
      -webkit-transition-property: none !important;
      transition-property: none !important;
      /*CSS transforms*/
      -o-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -webkit-transform: none !important;
      transform: none !important;
      /*CSS animations*/
      -webkit-animation: none !important;
      -moz-animation: none !important;
      -o-animation: none !important;
      -ms-animation: none !important;
      animation: none !important;
    }
  }
}

.introText {
  color: rgb(255, 255, 255);
  font-size: 600%;
  font-weight: 200;
  background-color: rgba($color: #000000, $alpha: 0.7);

  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.05em solid white; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.01em; /* Adjust as needed */

  @media (max-width: 1425px) {
    white-space: normal;
    overflow: visible;
    font-size: 300%;
  }
  @media (max-width: 575px) {
    border-right: none;
  }
}

@keyframes typing {
  from {
    width: 0;
    opacity: 1;
  }
  to {
    opacity: 1;
    width: 100%;
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
    opacity: 1;
  }
  99.9% {
    border-right: 0.05em solid white;
  }
  100% {
    border: none;
    opacity: 1;
  }
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.05em solid white;
  }
  100% {
    border: none;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}

.aboutMe {
  @extend %sectionDefaultStyles;
  background-color: #1f1d1d;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutMeTextWrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  margin-top: 5rem;
  justify-content: space-around;
  height: 40%;
  width: 60%;

  @media (max-width: 760px) {
    height: 60%;
  }

  @media (max-width: 440px) {
    margin-top: 1rem;
  }
}

%aboutMeTextDefault {
  color: white;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
}

.aboutMeText {
  @extend %aboutMeTextDefault;

  @media (max-width: 440px) {
    font-size: 1rem;
    margin-top: 1rem;
  }
}

.aboutMeTextItalic {
  @extend %aboutMeTextDefault;
  font-style: italic;
  margin-top: 12rem;
  font-size: 1.4rem;
  width: 75%;

  @media (max-width: 760px) {
    margin-top: 5rem;
  }

  @media (max-width: 440px) {
    margin-top: 2.5rem;
    font-size: 1rem;
  }
}

.technicalSkills {
  @extend %sectionDefaultStyles;
  background: radial-gradient(circle, rgba(61,61,61, 100), rgba(0, 0, 0, 100));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  height: auto;
}

.technicalSkillsText {
  @extend %aboutMeTextDefault;
  margin-top: 2rem;
  width: 85%;
  @media (max-width: 390px) {
    font-size: 1rem;
  }
}

.technicalSkillsList {
  margin-top: 5rem;
  color: white;
  font-size: 1.5rem;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 390px) {
    font-size: 1rem;
  }
}

.contact {
  @extend %sectionDefaultStyles;
  background-color: #171717;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;

    &:active {
      text-decoration: none;
    }
  }
}

.contactLinksWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 8rem 0px;
  height: 12rem;

  @media (max-width: 440px) {
    margin: 4rem 0px;
  }
}

.button {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 0.5rem;
  border-radius: 5px;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    animation-name: fillButton;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  @media (max-width: 1380px) {
    margin-top: 1rem;
  }
}

@keyframes fillButton {
  20% {
    color: black;
  }

  100% {
    background-color: rgb(255, 255, 255);
    color: black;
    font-weight: bold;
  }
}

.textBtnWrapper {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  width: 40%;
  justify-content: space-between;
  position: relative;

  @media (max-width: 1380px) {
    flex-direction: column;
    width: 100%;
  }
}

.contactText {
  color: rgb(255, 255, 255);
  width: 100%;

  @media (max-width: 435px) {
    font-size: 1rem !important;
  }
}

.email {
  color: white;
  font-size: 1.5rem;

  @media (max-width: 435px) {
    font-size: 1rem !important;
  }

  &:hover {
    cursor: pointer;
  }
}

.tooltip::before {
  background: rgba(247, 244, 244, 0.8);
  border-radius: 0.5em;
  bottom: 1.35em;
  color: white;
  color: black;
  font-weight: bold;
  font-size: 1rem;
  content: attr(title);
  display: block;
  left: 0.5em;
  margin-bottom: 1rem;
  padding: 0.3em 2rem;
  position: absolute;
  max-width: none !important;
  text-shadow: 0 1px 0 rgba(247, 244, 244, 0.8);
  white-space: nowrap;
  z-index: 98;
}

.tooltip::after {
  border: solid;
  border-color: rgba(247, 244, 244, 0.8) transparent;
  border-width: 0.4em 0.4em 0 0.4em;
  bottom: 1.75rem;
  content: "";
  display: block;
  left: 1.5em;
  position: absolute;
  z-index: 99;
}

.contactText {
  color: white;
  font-size: 1.5rem;
  white-space: nowrap;
}

.projects {
  @extend %sectionDefaultStyles;
  background-color: #1f1d1d;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1000px) {
    height: fit-content;
  }
}

.projectTitle {
  color: white;
  font-size: 1.5rem;
  width: 85%;
  margin-top: 1rem;
  @media (max-width: 420px) {
    font-size: 1rem;
  }
}

.projectCardWrapper {
  display: flex;
  margin-top: 3rem;
  justify-content: space-evenly;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    height: 100rem;
  }
}

.projectCard {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 27rem;
  width: 18rem;
  // background-color: red;

  &Side {
    height: 100%;
    transition: all 0.8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    border-radius: 0.3rem;
    box-shadow: 0 1.5rem 4rem rgba(27, 27, 27, 0.15);
    overflow: hidden;
  }

  &:hover &Front {
    transform: rotateY(-180deg);
  }

  &:hover &Back {
    transform: rotateY(0);
  }

  &Back {
    transform: rotateY(180deg);
    background-color: white;

    &Title {
      margin-top: 1rem;
    }
  }

  &BackText {
    margin-left: 1rem;
    margin-top: 2rem;
    color: black;
    position: absolute;
    width: 90%;
    text-align: center;
    background-color: white;
    font-weight: bolder;
    font-size: 1rem;
    text-align: justify;
  }

  &Image {
    background-size: cover;
    background-position: center;
    height: 8rem;
    background-blend-mode: screen;
    background-image: url("https://www.jbox.com.br/wp/wp-content/uploads/2018/12/kaguya-sama-love-is-a-war.jpg");

    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }

  &Content {
    background-color: white;
    display: flex;
    flex-direction: column;
    color: black;
    font-size: 1rem;
    font-weight: bolder;
    align-items: flex-start;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    height: 100%;
  }

  &Title {
    margin-top: 1rem;
    font-size: 1.2rem;
    align-self: center;
  }

  &Text {
    margin-left: 1rem;
    margin-top: 2rem;
  }

  &List {
    display: flex;
    flex-flow: column wrap;
    height: 30%;
    // justify-content: space-evenly;
    width: 100%;
    margin-top: 1rem;
    margin-left: 2rem;
    list-style: none;
    flex-direction: column;
    align-items: flex-start;
  }
}

*,
*::after,
*::before {
  @media (min-width: 2000px) {
    font-size: 1.2rem;
  }
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #383636;
}

*::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

  background-color: #555;
}


@mixin margin-auto {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}


